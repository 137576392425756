import Alert from "../../../../src/views/shared/common/alertToast";
const requestIntialState = {
    requestSent: false,
    message: '',
    handoutTitle: '',
    user: null
};
const initialState = {
    handouts: [],
    selectedHandouts: [],
    request: requestIntialState
};

const getHandouts = (action) => {
    if (action && action.payload && action.payload.data) {
        const {data} = action.payload;
        if (action.payload.status === 200) {
            const handouts = data.handouts;
            return handouts
        }
    }
    return initialState;
};
const getError = (action) => {
    console.log(action);
    if (action && action.error) {
        const {data, status} = action.error;
        if (status !== 200) {
            return {status, ...data}
        }
    }
    return {};
};
const addHandout = (state, action) => {
    if (action && action.payload) {
        const id = action.payload;
        let {selectedHandouts} = state;
        if (selectedHandouts) {
            if (id && !state.selectedHandouts.includes(id)) {
                selectedHandouts.push(id);
            }
            console.log(selectedHandouts, id);
            return selectedHandouts;
        }
        return initialState.selectedHandouts;
    }
};
const removeHandout = (state, action) => {
    if (action && action.payload) {
        const id = action.payload;

        const selectedHandouts = state.selectedHandouts.filter(handout => {
            return handout !== parseInt(id);
        });
        console.log(selectedHandouts, id);
        return selectedHandouts
    }
    return state.selectedHandouts;
};

function handoutReducer(state = initialState, action) {
    // console.log("handoutReducer", action.type, action, state)

    switch (action.type) {
        case 'GET_ALL_HANDOUTS': {
            return {...initialState, ...state}
        }
        case 'GET_ALL_HANDOUTS_SUCCESS': {
            const handouts = getHandouts(action);
            return {
                ...initialState,
                ...state,
                handouts
            };
        }
        case 'GET_SELECTED_HANDOUTS': {
            console.log(state.selectedHandouts);
            return {...state};
        }

        case 'ADD_HANDOUT': {
            const selectedHandouts = addHandout(state, action);
            // state.selectedHandouts = selectedHandouts;
            return {...state, selectedHandouts: selectedHandouts};
        }
        case 'REMOVE_HANDOUT': {
            const selectedHandouts = removeHandout(state, action);
            return {...state, selectedHandouts: selectedHandouts};
        }
        case 'RESET_HANDOUTS': {
            const selectedHandouts = initialState.selectedHandouts;
            return {...state, selectedHandouts: selectedHandouts};
        }
        case 'SEND_HANDOUT_SUCCESS': {
            return {
                ...state
            };
        }
        case 'RESET_HANDOUT_REQUEST': {
            return {
                ...initialState,
            };
        }
        case 'UPDATE_HANDOUT_REQUEST': {
            const {payload} = action;
            console.log({
            ...state,
                request: {...payload, requestSent:true}
            });
            return {
                ...state,
                request: {...payload}
            };
        }
        case 'SEND_HANDOUTS_FAIL': {
            const error = getError(action)
            return {
                ...state, error
            };
        }
        case 'REQUEST_HANDOUTS_FAIL': {
            console.log(action);
            // const error = getError(action);
            Alert(400, "Handout Request Failed");
            return {
                ...state
            };
        }
        case 'REQUEST_HANDOUTS_SUCCESS': {
            Alert(200, "Handout Request Sent!");
            return {
                ...state,
                request: {requestSent:true}
            };
        }


        default:
            return state;

    }
}


export default handoutReducer;