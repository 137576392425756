import {combineReducers} from 'redux';
import loginReducer from './loginReducer';
import handoutReducer from './handoutReducer'
import contactReducer from "./contactReducer";

const rootReducer = combineReducers({
    userDetails: loginReducer,
    handoutDetails: handoutReducer,
    contactDetails: contactReducer
});


export default rootReducer