import {action} from './index'

export const setlogin = (data) =>
    action({
        type: "SET_LOGIN",
        payload: {
            request: {
                url: '/auth/login',
                method: 'POST',
                data: JSON.stringify(data)
            }
        }
    })

export const validateToken = () =>
    action({
        type: "VALIDATE_TOKEN",
        payload: {
            request: {
                url: "/auth/token",
                method: "GET"
            }
        }
    });

export const forgotPassword = (data) =>
    action({
        type: "FORGOT_PASSWORD",
        payload: {
            request: {
                url: '/auth/forgot-password',
                method: 'POST',
                data: JSON.stringify(data)
            }
        }
    });

export const resetPassword = (data, token) =>
    action({
        type: "RESET_PASSWORD",
        payload: {
            request: {
                url: `/auth/reset-password/${token}`,
                method: 'PUT',
                data: JSON.stringify(data)
            }
        }
    });
export const setPassword = (data, token) =>
    action({
        type: "SET_PASSWORD",
        payload: {
            request: {
                url: `/auth/set-password/${token}`,
                method: 'PUT',
                data: JSON.stringify(data)
            }
        }
    });


export const logout = (data) =>
    action({
        type: "SET_LOG_OUT",
        payload: {
            request: {
                url: '/auth/logout',
                method: 'POST',
                data: JSON.stringify(data)
            }
        }
    })



