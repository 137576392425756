const initialState = {
    isLoggedIn: false,
    firstname: '',
    lastname: '',
    userId: '',
    email: '',
    roleId: '',
};

// result of login api
const getFormattedData = action => {
    if (action && action.payload && action.payload.data) {
        const {data} = action.payload;
        if (action.payload.status === 200) {
            const userData = {
                firstname: data.user.first_name,
                lastname: data.user.last_name,
                userId: data.user.id,
                email: data.user.email,
                roleId: data.user.role_id,
                isLoggedIn: true
            };
            return userData;
        }
    }
    // return {};
};


function loginReducer(state = initialState, action) {
// console.log("loginReducer",action.type, state);
    switch (action.type) {

        case 'SET_LOGIN': {
            return {
                ...state,
                ...action.payload
            };
        }
        case 'SET_PASSWORD_SUCCESS':
        case 'RESET_PASSWORD_SUCCESS': {
            return state;
        }
        case 'SET_LOGOUT': {
            return {};
        }
        // case"VALIDATE_TOKEN_SUCCESS":
        case 'SET_LOGIN_SUCCESS': {
            const formattedData = getFormattedData(action);
            return {
                ...state,
                ...formattedData,
            };
        }
        case 'SET_LOGIN_FAIL': {
            localStorage.removeItem("token");
            console.log(" SET_LOGIN_FAIL", state, initialState);
            return {
                ...state,
                ...initialState
            };
        }
        case 'RESET_PASSWORD_FAIL': {
            return state;
        }

        case 'SET_LOG_OUT_SUCCESS': {
            localStorage.removeItem("token");
            console.log(state);
            return {
                ...state,
                ...initialState
            };
        }
        case 'SET_LOG_OUT_FAIL': {
            console.log("SET_LOG_OUT_FAIL")
            localStorage.removeItem("token");
            return {};
        }
        default:
            return state;

    }
};
export default loginReducer;