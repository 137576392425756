const initialState = {
    contacts: [],
    selectedContact:[]
};

const getContacts = (action) => {
    if (action && action.payload && action.payload.data) {
        const {data} = action.payload;
        if (action.payload.status === 200) {
            return data.contacts;
        }
    }
};
const addContact = (state, action) => {
    if (action && action.payload) {
        const id = action.payload;
        return id;
    }
}

function contactReducer(state = initialState, action) {
    // console.log("contactReducer", action.type, action);

    switch (action.type) {
        case 'GET_ALL_CONTACTS_SUCCESS': {
            const contacts = getContacts(action);
            return {
                ...state,
                contacts
            };
        }
        case 'ADD_CONTACT': {
            const selectedContact = addContact(state, action);
            return {...state, selectedContact};
        }
        case 'RESET_CONTACT': {
            const selectedContact = initialState.selectedContact;
            return {...state, selectedContact};
        }

        default:
            return state;

    }
}




export default contactReducer;