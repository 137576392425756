import { toast } from 'react-toastify';
export default (status,message) => {
 if(status === 200){
   toast.success(message, {
     position: toast.POSITION.TOP_RIGHT,
     autoClose: 1500,
     hideProgressBar: true
   })
 }
 else{
   toast.error(message, {
     position: toast.POSITION.TOP_RIGHT,
     autoClose: 4000,
     hideProgressBar: true
   })
 }
}