import React, {Component} from 'react';
import './App.css';
import './App.scss';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {HashRouter, Route, Switch, Redirect} from 'react-router-dom';
import {connect} from 'react-redux'
import * as loginAction from "./shared/store/actions/loginActions";


const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
toast.configure();
// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const ForgotPassword = React.lazy(() => import('./views/Pages/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./views/Pages/ResetPassword'));
// const Register = React.lazy(() => import('./views/Pages/Register'));
// const Page404 = React.lazy(() => import('./views/Pages/Page404'));
// const Page500 = React.lazy(() => import('./views/Pages/Page500'));


class App extends Component {
    authRoutes = () =>
        <Switch>
            <Route exact path="/reset-password/:token" name="Forgot Password Page" render={props => <ResetPassword {...props} />}/>
            <Route exact path="/new-user/:token" name="Set Password" render={props => <ResetPassword {...props} setPassword={true}/>}/>
            <Route path="/*" name="SalesHandouts" render={props => <DefaultLayout {...props} />}/>
            {/*<Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />}/>*/}
            {/*<Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />}/>*/}

        </Switch>

    nonAuthRoutes = () =>
        <Switch>
            <Route path="/login" name="Login Page" render={props => <Login {...props} />}/>
            <Route path="/token/:token" name="Login Page" render={props => <Login {...props} />}/>
            {/*<Route exact path="/register" name="Register Page" render={props => <Register {...props} />}/>*/}
            <Route exact path="/forgot-password" name="Forgot Password Page" render={props => <ForgotPassword {...props} />}/>
            <Route exact path="/reset-password/:token" name="Forgot Password Page" render={props => <ResetPassword {...props} />}/>
            <Route exact path="/new-user/:token" name="Set Password" render={props => <ResetPassword {...props} setPassword={true}/>}/>
            <Redirect from="/" to="/login"/>
        </Switch>

    render() {
        const {isLoggedIn} = this.props;
        return (
            <div className="App">
                <HashRouter>
                    <React.Suspense fallback={loading()}>
                        {isLoggedIn ? this.authRoutes() : this.nonAuthRoutes()}
                    </React.Suspense>
                </HashRouter>
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    isLoggedIn: state.userDetails.isLoggedIn
})
const mapDispatchToProps = dispatch => ({
    logout: (data) => dispatch(loginAction.logout(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
