function getHeaderData(data) {
    const token = localStorage.getItem("token");

    let header = {
        'Content-Type': 'application/json'
    };
    if(token){
        header['Authorization'] = `Bearer ${token}`;
    }
    return header;
}
export const action = definition => {

    const data = {
        ...definition
    };

    if (data && data.payload && data.payload.request) {
        const header = data.payload.request.headers || {};
        const basicHeader = getHeaderData(definition);
        const updatedHeader = {
            ...basicHeader,
            ...header,
        };
        data.payload.request.headers = updatedHeader;
    }
    return data;
};