import React from 'react';
import ReactDOM from 'react-dom';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reducers from "./shared/store/reducers";
import {persistStore, persistReducer} from 'redux-persist'
import {PersistGate} from 'redux-persist/integration/react'

import axios from 'axios';
import axiosMiddleware from 'redux-axios-middleware';
import {createStore, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';

let prodUrl = 'https://api.saleshandouts.com';
// let prodUrl = 'http://api.saleshandouts.local';

const client = axios.create({ //all axios can be used, shown in axios documentation
    baseURL: prodUrl,
    responseType: 'json',
    withCredentials: true
});
const persistConfig = {
    key: 'root',
    storage,
};

const options = {
    // not required, but use-full configuration option
    returnRejectedPromiseOnError: true,
    interceptors: {

        request: [
            ({getState, dispatch}, config) => {
                // Request interception
                return config
            }


        ],
        response: [
            {
                success: ({dispatch}, response) => {
                    // Response interception
                    return response
                },
                error: ({dispatch}, error) => {
                    // Response Error Interception
                    return Promise.reject(error)
                }
            }
        ]
    }
}

const persistedReducer = persistReducer(persistConfig, reducers);


export const store = createStore(
    persistedReducer, //custom reducers
    applyMiddleware(
        axiosMiddleware(client, options), //second parameter options can optionally contain onSuccess, onError, onComplete, successSuffix, errorSuffix
    )
);

export const persistor = persistStore(store);

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            {/*<Loader/>*/}
            <App/>
        </PersistGate>
    </Provider>,
    document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
